@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Signika:wght@322&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap");

.primary {
  color: #7a7a7a;
}

.lazyloadinfo {
  width: 100vw;
  height: 100vh;
  font-size: 2rem;
  font-family: Poppins;
  text-align: center;
  margin-top: 5rem;
}

.main_home_wrapper {
  width: 100%;
  display: flex;
  border-radius: 20px;
}

.loginpage_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 5px 0px; */
}

.flexcol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fs_24 {
  font-size: 24px !important;
  font-family: "Mulish" !important;
}

.fs_12 {
  font-size: 12px !important;
  font-family: "Mulish" !important;
}

.fs_14 {
  font-size: 14px !important;
  font-family: "Mulish" !important;
}

.fs_16 {
  font-size: 16px !important;
  font-family: "Mulish" !important;
}

.fs_10 {
  font-size: 10px !important;
  font-family: "Mulish" !important;
}

.fs_20 {
  font-size: 20px !important;
  font-family: "Mulish" !important;
}

.font600 {
  font-weight: 600;
}

.flexrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumLast {
  text-decoration: none;
  color: #242424;
}

.breadcrum {
  text-decoration: none;
  color: #d12e29;
}

/* login screen css */
.login_screen_title {
  font-size: 32px !important;
  font-family: "Mulish" !important;
  font-weight: 700 !important;
}

.login_tagline {
  font-weight: 400;
  font-size: 14px;
  color: #6c6c6c;
  padding-bottom: 2rem;
}

.forgot_password1 {
  font-weight: 400;
  color: #6c6c6c;
  padding: 18px 0 35px 0;
  text-decoration: underline;
}

.no_account {
  font-family: "Mulish";
  font-weight: 700;
  padding: 1.5rem 0 1rem 0;
}

.heading {
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
}

.otp_headline {
  font-family: "Mulish" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}

.otp_time {
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.otp_resend {
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.otp_button {
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  padding-left: 70px !important;
  padding-right: 70px !important;

  /* width: 35px !important;
        height: 20px !important; */
}

.otp-box {
  width: 60px;
  height: 60px;
  background: #ffffff;
  margin-right: 18px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1rem;
}

.otp-box input {
  font-size: 14px;
  border-radius: 4px;
  width: 100% !important;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  outline: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  line-height: 21px;
  color: black;
  font-weight: 500;
}

.otp-box input:focus {
  border: 1px solid #aa3030;
}

.pointer {
  cursor: pointer;
}

.forgot_password {
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  color: #242424;
  text-decoration: none;
}

.email_id {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
}

.passwordpage_wrapper {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5rem;
}

.textfield_label {
  font-weight: 500 !important;
  font-family: "Mulish" !important;
}

.textfield_label_new {
  font-weight: 500 !important;
  font-family: "Mulish" !important;
  color: #7a7a7a;
  font-size: 16px;
  line-height: 1.5;
}

.textFieldWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.formCategory_new {
  font-family: "Mulish" !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 1.25;
  color: #101010;
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}

.flex_row {
  display: flex;
  align-items: center;
}

.textfield_label {
  font-family: "Mulish" !important;
  color: #3d525c !important;
  font-weight: 400 !important;
  padding-bottom: 8px;
}

.lawyer_form_laylout {
  display: grid;
  grid-template-columns: 50% 50%;
}

.font400 {
  font-weight: 400 !important;
  font-family: "Mulish" !important;
}

.font500 {
  font-weight: 500 !important;
  font-family: "Mulish" !important;
}

.font700 {
  font-weight: 700 !important;
  font-family: "Mulish" !important;
}

.profile_pic {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.add_profile_pic {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #727272 !important;
  margin-top: 18px !important;
}

.application_heading {
  font-family: "Mulish" !important;
  font-weight: 700 !important;
  /* margin: 2rem 0 !important; */
}

.upload_doc_wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(233, 233, 233, 0.25) !important;
  border-radius: 8px !important;
  display: grid;
  place-items: center;
}

.document_upld {
  width: 100%;
  display: flex;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  justify-content: space-between !important;
  padding: 0.8rem;
  margin-top: 1rem;
}

.font600 {
  font-weight: 600 !important;
  font-family: "Mulish" !important;
}

.typo_to_btn {
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  width: max-content;
  line-height: 1.25 !important;
  height: 100%;
}

.searchbar_wrapper {
  display: flex;
  border: 3px solid #f5f5f5;
  border-radius: 4px;
  padding: 4px 14px;
  width: 32%;
  max-width: 332px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(233, 233, 233, 0.25);
}

/* Lawyer Table */
.column_one {
  width: 152px !important;
  min-width: 152px !important;
  height: 52px !important;
  max-height: 52px !important;
  padding: 0px !important;
}

.column_two {
  width: 136px !important;
  min-width: 136px !important;
  height: 52px !important;
  max-height: 52px !important;
  padding: 0px !important;
}

.column_three {
  width: 144px !important;
  min-width: 144px !important;
  height: 52px !important;
  max-height: 52px !important;
  padding: 0px !important;
}

.column_four {
  width: 120px !important;
  min-width: 120px !important;
  height: 52px !important;
  max-height: 52px !important;
  padding: 0px !important;
}

.column_five {
  width: 128px !important;
  min-width: 128px !important;
  height: 52px !important;
  max-height: 52px !important;
  padding: 0px !important;
}

.column_six {
  width: 160px !important;
  min-width: 152px !important;
  height: 52px !important;
  max-height: 52px !important;
  padding: 0px !important;
}

.column_serial {
  width: 60px !important;
  min-width: 40px !important;
  height: 52px !important;
  max-height: 52px !important;
  padding: 0px !important;
}

.center_table_content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
}

.left_align_table_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;
}

.text_table {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
  white-space: nowrap;
  width: 112px;
}

.wrapText {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
  white-space: nowrap;
}

.tableSelect {
  font-family: Mulish;
  color: rgba(39, 40, 40, 1);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  width: 100%;
  background: transparent;
  border: none;
}

.tableSelect > option {
  border: none;
  cursor: pointer;
}

.tableSelect:focus-visible {
  outline: none !important;
}

#rowsPerPage {
  border-color: #e9e9e9;
  height: 28px;
  font-size: 14px;
  color: #242424;
  line-height: 1.25;
  border-radius: 4px;
  padding-left: 2px;
  cursor: pointer;
}

#rowsPerPage:focus-visible,
#rowsPerPage:hover {
  border-color: #aa3030;
  outline: #aa3030;
}

#rowsPerPage > option:checked {
  background-color: #fdecec !important;
}

#rowsPerPage > option {
  border-radius: 4px;
}

option.customOption {
  font-size: 14px;
  color: #242424;
}

#rowsPerPage > option:hover,
#rowsPerPage > option:active,
#rowsPerPage > option:focus,
#rowsPerPage > option::selection {
  background-color: #aa3030 !important;
  box-shadow: none;
}

.searchNavbarWrapper {
  background: #ffffff;

  margin-bottom: 30px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px 12px 25px;
}

.searchField {
  height: 40px !important;
  max-height: 40px !important;
  padding: 0px !important;
  border-radius: 8px;
  background: #f5f5f5;
  border: none;
  width: 300px;
  padding-left: 16px !important;
  color: #7a7a7a;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  border: none;
}

input.searchField:focus-visible {
  border: none !important;
  outline: none;
}

.textfield_label {
  font-family: "Mulish" !important;
  color: #3d525c !important;
  font-weight: 400 !important;
  padding-bottom: 8px;
}

.form_category_label {
  font-size: 22px !important;
  padding: 3rem 0 !important;
}

.font500 {
  font-weight: 500 !important;
  font-family: "Mulish" !important;
}

.font700 {
  font-weight: 700 !important;
  font-family: "Mulish" !important;
}

.profile_pic {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.add_profile_pic {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #727272 !important;
  margin-top: 18px !important;
}

/* .application_heading {
  font-family: "Mulish" !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  margin: 2rem 0 !important;
} */
.upload_doc_wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(233, 233, 233, 0.25) !important;
  border-radius: 8px !important;
  display: grid;
  place-items: center;
}

.document_upld {
  width: 100%;
  display: flex;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  justify-content: space-between !important;
  padding: 0.8rem;
  margin-top: 1rem;
}

.font600 {
  font-weight: 600 !important;
  font-family: "Mulish" !important;
}

.typo_to_btn {
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  width: max-content;
}

/* .searchbar_wrapper {
  display: flex;
  border: 3px solid #f5f5f5;
  border-radius: 4px;
  padding: 4px 14px;
  width: 332px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
} */
.sidebar_item:hover {
  background: #fdecec;
  border-radius: 0px 8px 8px 0px;
  border-left: 5px solid #aa3030;
}

/*Style for Doughnut Chart*/
.chartContainer {
  margin: 0 auto;
  max-width: 184px;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  max-height: 184px;
}

.chartInnerDisc {
  background: #fdfdff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 18%;
  right: 15%;
  bottom: 12%;
  left: 15%;
  /* top: 15%;
  right: 14%;
  bottom: 10%;
  left: 14%; */
  border-radius: 50%;
  padding: 1.25em 0;
  line-height: 120%;
  z-index: 1;
  /* Allow tooltips on top */
  overflow: hidden;
}

.chartInner {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 14%;
  right: 14%;
  bottom: 14%;
  left: 14%;
  border-radius: 50%;
  /* line-height: 120%; */
  z-index: -1;
  /* Allow tooltips on top */
  overflow: hidden;
  align-items: center;
  row-gap: 8px;
}
.disable-scroll {
  overflow: hidden !important;
}

.chartValue {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #242424;
}

.chartStatus {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6c6c6c;
}

.MuiPagination-ul > li > button:hover {
  background-color: #aa3030 !important;
  color: white;
}

.upload_box_wrapper {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-qiwgdb {
  /* padding: 10.5px !important; */
/* background-color: rgba(233, 233, 233, 0.5) !important; 
}
 */

.css-1tjpy9e-MuiFormLabel-root-MuiInputLabel-root,
.css-vo9b69 {
  line-height: 1 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1x5jdmq {
  padding: 10.5px !important;
  /* height: auto !important; */
  height: 44px !important;
  background-color: rgba(233, 233, 233, 0.5) !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1uvydh2 {
  padding: 10.5px !important;
  height: 44px !important;
  background-color: rgba(233, 233, 233, 0.5) !important;
}

.css-1u6rvhx-MuiInputBase-root-MuiOutlinedInput-root {
  color: rgba(0, 0, 0, 0.6) !important;
}

.admincard_wrapper {
  border-radius: 4px;
  border: 1px solid transparent;
  border-left: 6px solid transparent;
}

.admincard_wrapper:hover {
  /* cursor: pointer; */
  border: 1px solid #a33737;
  border-left: 6px solid #a33737;
}

/* .sidebarbox:hover{

} */

@media (min-width: 768px) {
  .sidebarBox {
    background: #fbf1f1;
    border-radius: 0px 4px 4px 0px;
    /* border-left: 6px solid #A33737 !important; */
  }

  .sidebarBox:hover {
    background: #fbf1f1;
    border-radius: 0px 4px 4px 0px;
    /* border-left: 6px solid #A33737; */
  }
  .EmojiPickerReact.epr-main {
    left: 34%;
    bottom: 150px;
    z-index: 1000;
  }

  .activenesteditems {
    background: #fbf1f1;
    border-radius: 0px 4px 4px 0px;
    /* border-left: 6px solid #A33737; */
  }

  .dropDownLine {
    margin-top: -0.5px;
    height: 32px;
  }

  .dropDownLineSecond {
    /* margin-top:-46.25px; */
    margin-top: -18.25px;
    height: 49px;
    width: "18.39px";
  }
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  overflow-y: auto;
}

@media (min-width: 1000px) {
  .sidebarBox {
    background: #fbf1f1;
    border-radius: 0px 8px 8px 0px;
    /* background: #D12E29; 
     border-radius: 8px 8px 8px 8px; */
    /* border-left: 6px solid #A33737 !important; */
  }

  .sidebarBox:hover {
    background: #fbf1f1;
    border-radius: 0px 8px 8px 0px;
    /* background: #D12E29;
    border-radius: 8px; */
    /* border-left: 6px solid #A33737; */
  }

  .activenesteditems {
    background: #fbf1f1;
    border-radius: 0px 8px 8px 0px;
    /* background: #D12E29;
    border-radius: 8px; */
    /* color: #ffffff !important; */
    /* border-left: 6px solid #A33737; */
  }
}

@media (min-width: 1200px) {
  .dropDownLine {
    /* margin-top:-18px; */
    margin-top: -1px;
    height: auto;
  }

  .dropDownLineSecond {
    /* margin-top:-43px; */
    margin-top: -24px;
    height: auto;
  }
}

.tablename {
  color: blue;
  text-decoration: underline;
}

.sidebarBox > .sidebarBorder {
  background-color: #a33737 !important;
}

.toggle_btns {
  background: #ffffff;
  padding: 16px 12px;
  border: 1px solid #e9e9e9;
  width: 250px;
  border-radius: 2px;
}

.profile_wrap {
  height: 135px;
  width: 200px;
  background: rgba(233, 233, 233, 0.12);
  /* border: 1px dashed #DFE0E0; */
  /* border-radius: 4px; */
  background-image: url(../src/assets/profile_border.svg);
}

.labelstyle {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.case_card_wrap {
  background: #ffffff;
  box-shadow: 0px 2px 16px #e3e3e3;
  padding: 24px;
}

.addcase {
  background: #242424;
  cursor: pointer;
  border: 1px solid #349f6e;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 4px;
}

/* Talk to Lawyer */
.case_desc {
  margin: 0;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition: height 2s;
}

.case_desc_open {
  margin: 0;
  width: 100%;
  display: block !important;
  transition: height 2s;
}

.view_more {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #d12e29;
  margin: 0;
  padding-top: 8px;
  cursor: pointer;
}

.customizedScrollbar {
  overflow-x: scroll !important;
  scrollbar-color: #f7e3e3 rgba(233, 233, 233, 0.5);
}

.textArea {
  padding: 12px 8px;
  width: 620px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  font-size: 14px;
  line-height: 18px;
  color: #242424;
}

.textArea:focus {
  border-color: #aa3030 !important;
}

.textArea:focus-visible {
  outline: 1px solid #aa3030 !important;
}

.textFiledOutine {
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
}

.textAreaDesc {
  padding: 12px 8px;
  width: 80%;
  border: 1px solid rgb(177 177 177);
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #242424;
  background-color: rgba(233, 233, 233, 0.5) !important;
  outline: #e9e9e9 !important;
  margin-top: -16px;
}

.textAreaDesc:focus-visible {
  border-color: #aa3030 !important;
}

.textAreaDesc:focus-visible {
  outline: 1px solid #aa3030 !important;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
} */

/* style for send email page */
.emailTextFiled {
  width: min(620px, 100%);
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 8px 8px;
  height: auto;
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 8px;
}

.emailChip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background: #f7e3e3;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: min-content;
  max-height: 32px;
}

.zeroMargin {
  margin: 0px;
}

.close_button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add_mail_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height:100%; */
}

.pointer {
  cursor: pointer;
}

.quill {
  /* height: 300px; */
  height: auto;
}

.ql-editor {
  min-height: 400px;
  overflow-y: scroll !important;
  height: 200px !important;
}

.ql-container {
  height: 90% !important;
}

.doc_box {
  background: #fbf1f1;
  margin: 0.8rem 0;
}

@media (max-width: 1000px) {
  .preview > p > span > img {
    object-fit: contain;
    width: 100%;
  }
}

.preview > p > img {
  object-fit: contain;
  width: 100%;
}

.preview > p > span > img {
  object-fit: contain;
  width: 100%;
}

/* premium laeyer onboard */
svg.addCase {
  cursor: pointer;
}

svg.addCase:hover > path {
  stroke: #aa3030;
}

svg.addCase:active > path {
  stroke: #fdecec;
}

.profileImage {
  object-fit: fill;
  border-radius: 4px;
  object-position: 50% 50%;
}

svg.remove_profile {
  cursor: pointer;
}

svg.remove_profile:hover > path.square {
  fill: #aa3030;
}

svg.remove_profile:hover > path.cross {
  fill: #fdecec;
}

/* Video*/
.player-wrapper {
  width: 100%;
  height: 100%;
}

.error-message {
  font-size: 12px;
  color: red;
}

.select_mui {
  min-width: 130px;
  background-color: white !important;
}

.select_mui > div {
  background-color: white !important;
}

/* Agents Table */

.column-small {
  min-width: 60px !important;
  width: 60px !important;
  /* max-width: 60px !important; */
}

.column-medium {
  min-width: 90px !important;
  width: 90px !important;
  /* max-width: 90px !important; */
}

.column-large {
  min-width: 110px !important;
  width: 110px !important;
  /* max-width: 110px !important; */
}

.column-xl {
  min-width: 140px !important;
  width: 140px !important;
  /* max-width: 140px !important; */
}
.column-xxl {
  min-width: 170px !important;
  width: 170px !important;
  /* max-width: 170px !important; */
}
/* Custom component */
svg.addCircleColored:active > path {
  fill: #a33737;
}

.addCircleColored {
  cursor: pointer;
}

/* SurakshaKavach*/
.surakshaKavachChip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  gap: 8px;
  background: #fff2f2;
  border: 1px solid #d12e29;
  border-radius: 4px;
  min-width: max-content;
}

.surakshaKavchChipName {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #d12e29;
}

.surakshaKavchChipTotal {
  background-color: #fff;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lawyerChatImage {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0px;
  top: 29px;
  border-radius: 100%;
  background: #f5f5f5;
}

.lawyerChatBoxWrapper {
  position: absolute;
  left: 60px;
  top: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  max-width: 80%;
}
.lawyerChatBox {
  width: max-content;
  height: auto;
  background: #fafafa;
  border-radius: 8px 8px 8px 0px;
  padding: 8px 12px 8px 12px;
  line-height: 20px;
}

.userChatImage {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0px;

  border-radius: 100%;
  background: #fff2f2;
}

.userChatBoxWrapper {
  position: absolute;
  right: 60px;
  top: 0px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  max-width: 80%;
}
.userChatBox {
  width: max-content;
  height: auto;
  background: #fff2f2;
  border-radius: 8px 8px 0px 8px;
  padding: 8px 12px 8px 12px;
  line-height: 20px;
}

/* LOADER */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
  background: #fff;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 24px solid rgba(209, 46, 41, 1);
  animation: prixClipFix 60s infinite linear;
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.rdrDayWeekend .rdrDayNumber span {
  color: red !important;
}
